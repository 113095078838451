var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ion-card',[(_vm.newsItem.image)?_c('img',{attrs:{"src":`${_vm.$apiStorageUrl}/${_vm.newsItem.image}`}}):_vm._e(),_c('ion-card-header',[_c('ion-card-subtitle'),_c('ion-card-title',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.newsItem.title)+" ")])],1),_c('ion-card-content',{staticClass:"news-card",attrs:{"color":"white"}},[((_vm.newsItem.text.length > _vm.maxLength) && !_vm.showAll)?_c('div',[_c('ion-text',{staticClass:"whitespace-pre-wrap",attrs:{"color":"white"}},[(!_vm.newsItem.readMore)?[_c('vue-markdown',{attrs:{"source":`${_vm.newsItem.text.slice(0, _vm.maxLength)}...`}})]:[_c('vue-markdown',{attrs:{"source":_vm.newsItem.text}})]],2),_c('ion-text',{staticClass:"mt-24",attrs:{"color":"white"},on:{"click":function($event){_vm.newsItem.readMore = !_vm.newsItem.readMore}}},[_c('strong',{staticClass:"inline-block mt-5"},[(!_vm.newsItem.readMore)?_c('p',[_c('ion-icon',{attrs:{"name":"arrow-down"}}),_vm._v(" "+_vm._s(_vm.$t('showmore')))],1):_c('p',[_c('ion-icon',{attrs:{"name":"arrow-up"}}),_vm._v(" "+_vm._s(_vm.$t('showless')))],1)])])],1):_c('ion-text',{staticClass:"whitespace-pre-wrap",attrs:{"color":"white"}},[_c('vue-markdown',{attrs:{"source":_vm.newsItem.text}})],1)],1),_c('AdvertisementSlides',{attrs:{"ad-slot":"news_item","margin-bottom":""}}),_c('ion-button',{staticClass:"ion-no-margin",attrs:{"expand":"full","fill":"solid"},on:{"click":function($event){return _vm.share(_vm.newsItem)}}},[_c('ion-icon',{attrs:{"slot":"start","name":"share-alt"},slot:"start"}),_vm._v(" "+_vm._s(_vm.$t('share'))+" ")],1)],1),_c('web-social-share',{domProps:{"show":_vm.showWebSocial,"share":{
      displayNames: true,
          config: [
            {
              facebook: {
                socialShareUrl: _vm.socialShareUrl,
              },

            },
            {
              whatsapp: {
                socialShareUrl: _vm.socialShareUrl,
              },
            },
            {
              copy: {
                socialShareUrl: _vm.socialShareUrl,
              },
            },
            {
              twitter: {
                socialShareUrl: _vm.socialShareUrl,
              },
            },
            {
              email: {
                socialShareBody: _vm.socialShareUrl,
              },
            }
          ],
    }},on:{"closed":function($event){_vm.showWebSocial = false}}},[_c('ion-icon',{staticClass:"icon-web-share",staticStyle:{"display":"block"},attrs:{"slot":"facebook","name":"logo-facebook","color":"white"},slot:"facebook"}),_c('ion-icon',{staticClass:"icon-web-share",staticStyle:{"display":"block"},attrs:{"slot":"whatsapp","name":"logo-whatsapp","color":"white"},slot:"whatsapp"}),_c('ion-icon',{staticClass:"icon-web-share",staticStyle:{"display":"block"},attrs:{"slot":"twitter","name":"logo-twitter","color":"white"},slot:"twitter"}),_c('ion-icon',{staticClass:"icon-web-share",staticStyle:{"display":"block"},attrs:{"slot":"copy","name":"copy","color":"white"},slot:"copy"}),_c('ion-icon',{staticClass:"icon-web-share",staticStyle:{"display":"block"},attrs:{"slot":"email","name":"mail","color":"white"},slot:"email"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }