<template>
  <div>
    <ion-card>
      <img v-if="newsItem.image" :src="`${$apiStorageUrl}/${newsItem.image}`">

      <ion-card-header>
        <ion-card-subtitle />
        <ion-card-title color="white">
          {{ newsItem.title }}
        </ion-card-title>
      </ion-card-header>

      <ion-card-content color="white" class="news-card">
        <div v-if="(newsItem.text.length > maxLength) && !showAll">
          <ion-text color="white" class="whitespace-pre-wrap">
            <template v-if="!newsItem.readMore">
              <vue-markdown :source="`${newsItem.text.slice(0, maxLength)}...`" />
            </template>
            <template v-else>
              <vue-markdown :source="newsItem.text" />
            </template>
          </ion-text>

          <ion-text
            color="white"
            class="mt-24"
            @click="newsItem.readMore = !newsItem.readMore"
          >
            <strong class="inline-block mt-5">

              <p v-if="!newsItem.readMore"><ion-icon name="arrow-down" /> {{ $t('showmore') }}</p>
              <p v-else><ion-icon name="arrow-up" /> {{ $t('showless') }}</p>
            </strong>
          </ion-text>
        </div>

        <ion-text v-else color="white" class="whitespace-pre-wrap">
          <vue-markdown :source="newsItem.text" />
        </ion-text>
      </ion-card-content>
      <AdvertisementSlides ad-slot="news_item" margin-bottom />
      <ion-button
        @click="share(newsItem)"
        expand="full"
        fill="solid"
        class="ion-no-margin"
      >
        <ion-icon slot="start" name="share-alt" />
        {{ $t('share') }}
      </ion-button>
    </ion-card>
    <web-social-share
      :show.prop="showWebSocial"
      @closed="showWebSocial = false"
      :share.prop="{
        displayNames: true,
            config: [
              {
                facebook: {
                  socialShareUrl,
                },

              },
              {
                whatsapp: {
                  socialShareUrl,
                },
              },
              {
                copy: {
                  socialShareUrl,
                },
              },
              {
                twitter: {
                  socialShareUrl,
                },
              },
              {
                email: {
                  socialShareBody: socialShareUrl,
                },
              }
            ],
      }"
    >
      <ion-icon slot="facebook" name="logo-facebook" style="display:block;" class="icon-web-share" color="white" />
      <ion-icon name="logo-whatsapp" slot="whatsapp" style="display:block;" class="icon-web-share" color="white" />
      <ion-icon name="logo-twitter" slot="twitter" style="display:block;" class="icon-web-share" color="white" />
      <ion-icon name="copy" slot="copy" style="display:block;" class="icon-web-share" color="white" />
      <ion-icon name="mail" slot="email" style="display:block;" class="icon-web-share" color="white" />
    </web-social-share>
  </div>
</template>

<script>
    import VueMarkdown from 'vue-markdown'
    import AdvertisementSlides from "../../components/AdvertisementSlides";
    import { Share } from '@capacitor/share';

    export default {
      components: {
        VueMarkdown,
        AdvertisementSlides,
      },
      props: {
        showAll: {
          type: Boolean,
          default: false,
        },
        newsItem: {
          type: Object,
          required: true
        }
      },

      data() {
        return {
          socialShareUrl: `${this.$appUrl}${this.$router.resolve({ name: 'news-single', params: { id: this.newsItem.id }}).href}`,
          maxLength: 100,
          showWebSocial: false,
        };
      },
      methods: {
        share(newsItem) {

          if(navigator && navigator.share) {
            this.shareNative(newsItem);
          } else {
            this.shareWeb();
          }

        },
        shareWeb() {
          this.showWebSocial = true;
        },
        shareNative(newsItem) {
          Share.share({
            title: newsItem.title,
            text: newsItem.text,
            url: this.socialShareUrl,
            dialogTitle: 'Share with friends',
          })
        },
        async onCopyLink() {
          const toast = await this.$ionic.toastController.create({
            color: 'success',
            duration: 3000,
            message: 'Link is copied to clipboard',
          });

          await toast.present();
        },
      }
    }
</script>

<style>
.news-card a {
  color: var(--ion-color-moto);
}

.icon-web-share {
  font-size: 25px;
}
</style>